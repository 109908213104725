<!-- 走进华都 -->
<template>
  <div class="intoHuadu-container">
    <navSecondPageVue type="IntoHuadu"></navSecondPageVue>
  </div>
  <Tabs :routerProps="routerProps"></Tabs>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component"></component>
    </keep-alive>
  </router-view>
</template>
<script setup>
import navSecondPageVue from '../components/navSecondPage.vue'
import Tabs from '../components/Tabs.vue'

const routerProps = [
  {
    name: '集团概况',
    path: '/IntoHuadu/Group'
  },
  {
    name: '组织架构',
    path: '/IntoHuadu/Organization'
  },
  {
    name: '企业文化',
    path: '/IntoHuadu/Company'
  },
  {
    name: '发展历程',
    path: '/IntoHuadu/Develop'
  },
  {
    name: '企业荣誉',
    path: '/IntoHuadu/Honor'
  },
  {
    name: '社会公益',
    path: '/IntoHuadu/World'
  }
]
</script>
<style lang="stylus" scoped></style>
